import React, { useContext } from "react"
import { DarkModeContext } from "../context/DarkModeContext";
import Style from './toggle.module.scss'

const Toggle = () => {

    const {darkMode, toggle}= useContext(DarkModeContext);

    const handleClick = () => {
        toggle();
    };
    return (
        <div>
            <label className={Style.switch}>
                <input
                type="checkbox"
                aria-label="Switch mode"
                onChange={e => handleClick()}
                checked={darkMode}
                />
                <span className={Style.slider + ' ' + Style.round}></span>
            </label>
        </div>
    )
    

}

export default Toggle
