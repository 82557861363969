import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const Metadata = ({ pathname, locale }) => {
  const {
    site: {
      siteMetadata: { 
          siteUrl, 
          fr: {
            titleFr, 
            descriptionFr
          }, 
          en: {
            titleEn, 
            descriptionEn 
          }
        },
    },
  } = useStaticQuery(graphql`
    query siteMetadata {
        site {
            siteMetadata {
                siteUrl
                fr {
                    descriptionFr
                    titleFr
                }
                en {
                  descriptionEn
                  titleEn
                }
            }
        }
    }
  `)
  return (
    <Helmet defer={false} defaultTitle={locale === 'fr' ? titleFr : titleEn} titleTemplate={`%s | ${locale === 'fr' ? titleFr : titleEn}`}>
        <html lang={locale} />
        <link rel="canonical" href={`${siteUrl}/${pathname}`} />
        <meta charSet="utf-8" />
        <meta name="description" content={locale === 'fr' ? descriptionFr : descriptionEn} />
        <meta name="docsearch:version" content="2.0" />
        <meta
            name="viewport"
            content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />

        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={locale === 'fr' ? 'fr_FR' : 'en_US'} />
        <meta property="og:site_name" content="timotheebinet.com" />
        {/*<meta property="og:image" content={`${siteUrl}${gatsbyIcon}`} />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />*/}

        {/*<meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={twitter} />*/}
    </Helmet>
  )
}

export default Metadata