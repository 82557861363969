import React from "react";

import Style from './skillicon.module.scss'

const SkillIcon = ({ icon, name, color }) => (
    <div style={{backgroundColor: color}} className={Style.icon} title={name}>
        <img src={icon} alt={name} className={Style.img}  />
    </div>
);

export default SkillIcon;