import React from "react"
import Language from '../components/Language'
import Toggle from '../components/Toggle'
import Navigation from '../components/Navigation'

import Style from './header.module.scss'

class Header extends React.Component {

    render(){

        return (
            <div className={Style.header}> 
                <div className={Style.header_left}>
                    <Navigation />
                </div>
                <div className={Style.header_right}>
                    <Toggle />
                    <Language />
                </div>
            </div>
        )
    }

}

export default Header
