import React from "react"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Style from './title.module.scss'

const Title = (props) => {

    const {translation} = props;
    const {t} = useTranslation();
    return (
        <h1 className={Style.title_h1}>{t(translation)}</h1>
    )
}

export default Title;