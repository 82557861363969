import React from "react"
import Header from '../components/Header'
import Metadata from '../components/Metadata'
import {useI18next} from 'gatsby-plugin-react-i18next';

import Style from './layout.module.scss'

const Layout = ({ children }) => {

    const {language} = useI18next();

    return (
        <div>
        
            <Metadata locale={language} />
            <Header />
            <div className={Style.wrapper}>
                {children}
                <h4 className={Style.tbcom}>timotheebinet.com</h4>
            </div>
        </div>
    )
}

export default Layout