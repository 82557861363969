
import React from "react"
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import Style from './language.module.scss'

const languageName = {
  fr: "FR",
  en: "EN",
}

const Language = () => {
  const {languages, originalPath, language} = useI18next();
  return (
    <div className={Style.language_wrapper}>
      {languages.map((lng) => (
            <Link key={lng} to={originalPath} language={lng} className={language === lng ? Style.link + ' ' + Style.current : Style.link + ' ' +  Style.other}>
              {languageName[lng]}
            </Link>
        ))}
    </div>
  )
}

export default Language