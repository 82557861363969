import React, { useState } from "react"
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

import Style from './navigation.module.scss'
import MenuSlider from "./MenuSlider";

const Navigation = () => {
    const [showMenu,setShowMenu] = useState(false);
    const {t} = useTranslation();

    const handleClick = () => {
        setShowMenu(!showMenu);
    };

        return (
            <>
                <div className={Style.navigation_wrapper}>
    
                    <div onClick={handleClick} className={showMenu ? Style.nav_icon + ' ' + Style.open + ' ' + Style.fixed : Style.nav_icon}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>     
                    <div className={Style.navigation_wrapper_txt}>
                        <Link className={Style.link} to="/">
                            {t("go_home")}
                        </Link>
                        <Link className={Style.link} to="/profile/">
                            {t("go_profile")}
                        </Link>
                        <Link className={Style.link} to="/skills/">
                            {t("go_skills")}
                        </Link>
                        <Link className={Style.link} to="/works/">
                            {t("go_works")}
                        </Link>
                        <Link className={Style.link} to="/blog/">
                            {t("go_blog")}
                        </Link>
                        <Link className={Style.link} to="/contact/">
                            {t("go_contact")}
                        </Link>
                    </div>
                </div>
                <MenuSlider hidden={showMenu} onStateMenuChange={handleClick}/>
            </>
        )
}

export default Navigation
