import React from "react"
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

import Style from './menuslider.module.scss'


const MenuSlider = (props) => {
    const {t} = useTranslation();
    return (
        <div className={!props.hidden ? Style.menuContainer + " " + Style.hidden : Style.menuContainer }>
            <Link className={Style.link} to="/">
                {t("go_home")}
            </Link>
            <Link className={Style.link} to="/profile/">
                {t("go_profile")}
            </Link>
            <Link className={Style.link} to="/skills/">
                {t("go_skills")}
            </Link>
            <Link className={Style.link} to="/works/">
                {t("go_works")}
            </Link>
            <Link className={Style.link} to="/blog/">
                {t("go_blog")}
            </Link>
            <Link className={Style.link} to="/contact/">
                {t("go_contact")}
            </Link>
        </div>
    )
}

export default MenuSlider
