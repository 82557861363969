import React from "react"
import Layout from '../layouts/layout'
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { Helmet } from "react-helmet"

import Style from './skills.module.scss'
import { graphql } from "gatsby";


// lang
import javascriptIcon from "../images/icons/javascript.svg"
import phpIcon from "../images/icons/php.svg"
import goIcon from "../images/icons/golang.svg"
import pythonIcon from "../images/icons/python.svg"
import htmlIcon from "../images/icons/html.svg"
import cssIcon from "../images/icons/css.svg"

// framework
import reactIcon from "../images/icons/react.svg"
import gatsbyIcon from "../images/icons/gatsby.svg"
import vueIcon from "../images/icons/vue.svg"
import symfonyIcon from "../images/icons/symfony.svg"
import nestjsIcon from "../images/icons/nestjs.svg"

// db
import mysqlIcon from "../images/icons/mysql.svg"
import firebaseIcon from "../images/icons/firebase.svg"
import mongodbIcon from "../images/icons/mongodb.svg"

// cms
import strapiIcon from "../images/icons/strapi.svg"
import wordpressIcon from "../images/icons/wordpress.svg"
import directusIcon from "../images/icons/directus.svg"
import prismicIcon from "../images/icons/prismic.svg"

// others
import gitIcon from "../images/icons/git.svg"
import nodeIcon from "../images/icons/nodejs.svg"
import shopifyIcon from "../images/icons/shopify.svg"
import netlifyIcon from "../images/icons/netlify.svg"
import dockerIcon from "../images/icons/docker.svg"

import SkillIcon from "../components/SkillIcon";
import Title from "../components/Title";

// https://worldvectorlogo.com/fr/downloaded/gatsby

const SkillIcons = [
    {
        icon: htmlIcon,
        name: "HTML 5",
        color: "#ffffff"
    },
    {
        icon: cssIcon,
        name: "CSS 3",
        color: "#ffffff"
    },
    {
        icon: javascriptIcon,
        name: "Javascript",
        color: "#f0db4f"
    },
    {
        icon: goIcon,
        name: "Go",
        color: "#00acd7"
    },
    {
        icon: phpIcon,
        name: "PHP",
        color: "#8993be"
    },
    {
        icon: pythonIcon,
        name: "Python",
        color: "#ffffff"
    },

]
const FrameworkIcons = [
    {
        icon: reactIcon,
        name: "React",
        color: "#61dafb"
    },
    {
        icon: gatsbyIcon,
        name: "Gatsby",
        color: "#744c9e"
    },
    {
        icon: vueIcon,
        name: "Vue",
        color: "#ffffff"
    },
    {
        icon: symfonyIcon,
        name: "Symfony",
        color: "#1A171B"
    },
    {
        icon: nestjsIcon,
        name: "NestJS",
        color: "#FFFFFF"
    }
]

const DatabaseIcons = [
    {
        icon: mongodbIcon,
        name: "MongoDB",
        color: "#FFFFFF"
    },
    {
        icon: mysqlIcon,
        name: "MySQL",
        color: "#00546B"
    },
    {
        icon: firebaseIcon,
        name: "Firebase",
        color: "#FFFFFF"
    }
]
const CmsIcons = [
    {
        icon: strapiIcon,
        name: "Strapi",
        color: "#FFFFFF"
    },
    {
        icon: directusIcon,
        name: "Directus",
        color: "#64f"
    },
    {
        icon: prismicIcon,
        name: "Prismic",
        color: "#FFFFFF"
    },
    {
        icon: wordpressIcon,
        name: "Wordpress",
        color: "#00749a"
    }
]
const OtherIcons = [
    {
        icon: gitIcon,
        name: "Git",
        color: "#DE4C36"
    },
    {
        icon: dockerIcon,
        name: "Docker",
        color: "#2496ed"
    },
    {
        icon: netlifyIcon,
        name: "Netlify",
        color: "#FFFFFF"
    },
    {
        icon: nodeIcon,
        name: "Node",
        color: "#8CC84B"
    },
    {
        icon: shopifyIcon,
        name: "Shopify",
        color: "#FFFFFF"
    }
]

const Skills = () => {
    const {t} = useTranslation();
    return (
        <div>
            <Layout>
                
                <Helmet>
                    <title>{t("skills_title" )}</title>
                </Helmet>
                <Title translation="skills_title"/>
                <div className={Style.wrapper}>
                                                
       



                    <h2>{t("skills_lang" )}</h2>
                        <div className={Style.tech_wrapper}>
                            {
                            SkillIcons.map(({ icon, name, color }, key) => (
                                <SkillIcon key={key} icon={icon} name={name} color={color} />
                            ))
                            }
                        </div>

                        <h2>{t("skills_fram" )}</h2>
                            <div className={Style.tech_wrapper}>
                                {
                                FrameworkIcons.map(({ icon, name, color }, key) => (
                                    <SkillIcon key={key} icon={icon} name={name} color={color} />
                                ))
                                }
                            </div>                  

                        <h2>{t("skills_bdd" )}</h2>
                            <div className={Style.tech_wrapper}>
                                {
                                DatabaseIcons.map(({ icon, name, color }, key) => (
                                    <SkillIcon key={key} icon={icon} name={name} color={color} />
                                ))
                                }
                            </div>

                        <h2>{t("skills_cms" )}</h2>
                            <div className={Style.tech_wrapper}>
                                {
                                CmsIcons.map(({ icon, name, color }, key) => (
                                    <SkillIcon key={key} icon={icon} name={name} color={color} />
                                ))
                                }
                            </div>

                        <h2>{t("skills_others" )}</h2>
                            <div className={Style.tech_wrapper}>
                                {
                                OtherIcons.map(({ icon, name, color }, key) => (
                                    <SkillIcon key={key} icon={icon} name={name} color={color} />
                                ))
                                }
                            </div>
                </div>
            </Layout>
        </div>
    )
}

export default Skills

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;